const useDepthColoring = () => {
  const reduceOpacity = (color: string, percent: number): string => {
    // Extract numbers
    const rgba = color.match(/\d+\.?\d*/g) || [];
    // parse to float number
    const originalOpacity = parseFloat(rgba[3]) || 1;
    // Apply the percent and ensure it's not less than 0
    const newOpacity = Math.max(originalOpacity - percent / 100, 0);
    return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${newOpacity.toFixed(1)})`;
  };

  const getDepthColor = (
    color: string | undefined,
    depth: number,
    index: number
  ) => {
    let c = color;
    if (depth === 0) {
      c = '#F48282';
    }
    if (depth === 1) {
      c = '#FFFF00';
    }
    if (depth === 2) {
      c = categoryColors[index];
    } else if (depth > 2 && color) {
      c = reduceOpacity(color, 20);
    }
    return c;
  };

  return { getDepthColor, reduceOpacity };
};

export default useDepthColoring;

const categoryColors = [
  'rgba(142,217,115,1)',
  'rgba(216,109,205,1)',
  'rgba(97,203,243,1)',
  'rgba(190,80,20,1)',
];
