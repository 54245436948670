import './categorypropertymatrix.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useEffect, useState } from 'react';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import {
  ProductCategoryResponse,
  ProductGroupMappingResponse,
  ProductGroupResponse,
} from '../../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import CategoryPropertyMatrixGroup from './categorypropertymatrixgroup/CategoryPropertyMatrixGroup';
import ToggleSwitch from '../../../../elements/toggleswitch/ToggleSwitch';
import ToolsMenu from '../../../../features/list/listcontrols/toolsmenu/ToolsMenu';
import { ReactComponent as IconReload } from '../../../../../assets/icon/reload.svg';

const CategoryPropertyMatrix = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.productCategories.matrix',
  });
  const api = usePetCloudApi();
  const productCategoriesApi = api.productCategoriesApi();
  const productGroupsApi = api.productGroupsApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const errorHandler = useErrorHandler();

  const [categories, setCategories] = useState<
    ProductCategoryResponse[] | null
  >(null);
  const [productGroups, setProductGroups] = useState<
    ProductGroupResponse[] | null
  >(null);
  const [productGroupMappings, setProductGroupMappings] = useState<
    ProductGroupMappingResponse[] | null
  >(null);

  const [isShowOnlyRequired, setIsShowOnlyRequired] = useState(false);
  const [isHideProductGroups, setIsHideProductGroups] = useState(false);

  useEffect(() => {
    getCategories();
    getProductGroups();
    getProductGroupMappings();
  }, []);

  const getProductGroupMappings = () => {
    setProductGroupMappings(null);
    productGroupMappingsApi
      .productGroupMappingsGetProductGroupMappings()
      .then((response) => {
        console.log(response.data);
        setProductGroupMappings(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getCategories = () => {
    setCategories(null);
    productCategoriesApi
      .productCategoriesGetProductCategories(true)
      .then((response) => {
        console.log(response.data);
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductGroups = () => {
    setProductGroups(null);
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response.data);
        setProductGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderTable = (
    categories: ProductCategoryResponse[],
    availableProductGroups: ProductGroupResponse[],
    productGroupMappings: ProductGroupMappingResponse[]
  ) => {
    return (
      <table>
        <thead>
          <tr className={'categoryPropertyMatrix-table-head'}>
            <th className={'categoryPropertyMatrix-table-cell-sticky'}>
              {'Kategorien & Produktgruppen'}
            </th>
            <th className={'categoryPropertyMatrix-table-cell'}>
              {'Eigenschaften & Validierung'}
            </th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, i) => {
            return (
              <CategoryPropertyMatrixGroup
                availableProductGroups={availableProductGroups}
                category={category}
                depth={0}
                index={i}
                isShowOnlyRequired={isShowOnlyRequired}
                isHideProductGroups={isHideProductGroups}
                productGroupMappings={productGroupMappings}
              />
            );
          })}
        </tbody>
      </table>
    );
  };

  if (categories && productGroups && productGroupMappings) {
    return (
      <div className={'categoryPropertyMatrix'}>
        <div className={'categoryPropertyMatrix-actions'}>
          <ToolsMenu
            active={true}
            actions={[
              {
                icon: <IconReload />,
                cta: t('actions.reloadProductGroupMappings'),
                ctaAlreadyTranslated: true,
                action: getProductGroupMappings,
              },
              {
                icon: <IconReload />,
                cta: t('actions.reloadProductGroups'),
                ctaAlreadyTranslated: true,
                action: getProductGroups,
              },
              {
                icon: <IconReload />,
                cta: t('actions.reloadCategories'),
                ctaAlreadyTranslated: true,
                action: getCategories,
              },
            ]}
          />
          <ToggleSwitch
            toggled={isShowOnlyRequired}
            toggle={() => setIsShowOnlyRequired(!isShowOnlyRequired)}
            label={t('actions.isShowOnlyRequired')}
            smallSwitch
            smallLabel
          />
          <ToggleSwitch
            toggled={isHideProductGroups}
            toggle={() => setIsHideProductGroups(!isHideProductGroups)}
            label={t('actions.isHideProductGroups')}
            smallSwitch
            smallLabel
          />
        </div>
        <div className={'categoryPropertyMatrix-table'}>
          {renderTable(categories, productGroups, productGroupMappings)}
        </div>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default CategoryPropertyMatrix;
