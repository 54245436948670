import './productgroupmappingedit.css';
import {
  CreateProductGroupMappingRequest,
  ProductGroupMappingResponse,
  ProductGroupResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
  PropertyValidationRuleResponse,
  UpdateProductGroupMappingRequest,
} from '../../../../api/petcloudapi/api';
import Input from '../../../../elements/input/Input';
import { useTranslation } from 'react-i18next';
import { Check } from '../../../../elements/selectors/Selectors';
import ToggleSwitch from '../../../../elements/toggleswitch/ToggleSwitch';
import PropertyBrowser from '../../../../features/propertybrowser/PropertyBrowser';
import { useEffect, useState } from 'react';
import PropertyValidationRuleArray from '../../propertyvalidationrules/propertyvalidationrulearray/PropertyValidationRuleArray';
import ProductGroupsArray from '../../productgroups/productgrouparray/ProductGroupsArray';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import useUserTools from '../../../../hooks/useUserTools';

interface ProductGroupMappingEditProps {
  request: CreateProductGroupMappingRequest | UpdateProductGroupMappingRequest;
  update: (
    request: CreateProductGroupMappingRequest | UpdateProductGroupMappingRequest
  ) => void;
  availableProductGroups: ProductGroupResponse[];
  availablePropertyValidationRules: PropertyValidationRuleResponse[];
  refreshAvailablePropertyValidationRules: () => void;
}

const getPropertyGroups = (response: ProductGroupMappingResponse) => {
  return response.propertyGroup ? [response.propertyGroup] : [];
};

const ProductGroupMappingEdit: React.FC<ProductGroupMappingEditProps> = ({
  request,
  update,
  availableProductGroups,
  availablePropertyValidationRules,
  refreshAvailablePropertyValidationRules,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.productGroupMappings.edit',
  });
  const api = usePetCloudApi();
  const propertyGroupsApi = api.propertyGroupsApi();
  const errorHandler = useErrorHandler();
  const { excludeUnlockedOptions } = useUserTools();
  const [selectedProductGroupIds, setSelectedProductGroupIds] = useState<
    string[] | null
  >(request.productGroupIds ?? null);
  const [availablePropertyGroups, setAvailablePropertyGroups] = useState<
    PropertyGroupResponse[] | null
  >(null);
  const [selectedPropertyOptions, setSelectedPropertyOptions] = useState<
    PropertyOptionResponse[]
  >((request as ProductGroupMappingResponse).propertyOptions ?? []);
  const [selectedPropertyGroups, setSelectedPropertyGroups] = useState<
    PropertyGroupResponse[]
  >(getPropertyGroups(request as ProductGroupMappingResponse));

  useEffect(() => {
    getAvailableProperties();
  }, [selectedProductGroupIds]);

  const getAvailableProperties = () => {
    setAvailablePropertyGroups(null);
    propertyGroupsApi
      .propertyGroupsGetPropertyGroups(
        undefined,
        undefined,
        excludeUnlockedOptions(),
        undefined,
        undefined,
        selectedProductGroupIds ?? undefined
      )
      .then((response) => {
        console.log(response);

        // filter propertyGroups so that only groups that are shared among selected productGroups are visible
        const productGroups = availableProductGroups.filter((g) =>
          selectedProductGroupIds?.includes(g.id)
        );
        const propertyGroups = response.data.filter((p) => {
          const notFound = productGroups.map((g) => {
            return !!g.propertyGroups?.find((x) => x.id === p.id);
          });
          return !notFound.includes(false);
        });
        setAvailablePropertyGroups(propertyGroups);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductGroupsFromIds = (ids: string[] | null | undefined) => {
    const groups: ProductGroupResponse[] = [];
    ids?.forEach((id) => {
      const g = availableProductGroups.find((x) => x.id === id);
      if (g) {
        groups.push(g);
      }
    });
    return groups;
  };

  // update request propertyOptionIds when selection changes
  useEffect(() => {
    const ids = selectedPropertyOptions.map((x) => x.id);
    update({
      ...request,
      propertyOptionIds: ids.length > 0 ? ids : null,
    });
  }, [selectedPropertyOptions]);

  // update request propertyGroupId when selection changes
  useEffect(() => {
    update({
      ...request,
      propertyGroupId: selectedPropertyGroups[0]?.id ?? 'null',
    });
  }, [selectedPropertyGroups]);

  return (
    <div className={'productGroupMappingEdit'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('name')}
            content={request.name}
            update={(value) => {
              update({ ...request, name: value });
            }}
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('description')}
            translatedContent={
              request.description !== undefined ? request.description : null
            }
            update={(value, lang) => {
              if (lang)
                update({
                  ...request,
                  description: {
                    ...request.description,
                    [lang]: value,
                  },
                });
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <ToggleSwitch
            title={t('isOptional')}
            toggled={request.isOptional}
            toggle={() =>
              update({
                ...request,
                isOptional: !request.isOptional,
              })
            }
          />
        </div>
      </div>
      <ProductGroupsArray
        productGroups={getProductGroupsFromIds(request.productGroupIds)}
        availableProductGroups={availableProductGroups}
        onDelete={(index) => {
          const ids = request.productGroupIds;
          if (ids) {
            ids?.splice(index, 1);
            update({ ...request, productGroupIds: ids });
            setSelectedProductGroupIds([...ids]);
          }
        }}
        onSubmit={(productGroupIds) => {
          update({ ...request, productGroupIds: productGroupIds });
          setSelectedProductGroupIds(productGroupIds);
        }}
      />
      <div className={'productGroupMappingEdit-heading'}>{t('properties')}</div>
      {availablePropertyGroups ? (
        <div className={'productGroupMappingEdit-propertyBrowser'}>
          <PropertyBrowser
            availablePropertyGroups={availablePropertyGroups}
            selectedPropertyOptions={selectedPropertyOptions}
            updateSelectedPropertyOptions={(options) => {
              const optionInUnselectedGroup = options.find(
                (o) => o.propertyGroupId !== selectedPropertyGroups[0]?.id
              );
              if (optionInUnselectedGroup) {
                const g = availablePropertyGroups.find(
                  (p) => p.id === optionInUnselectedGroup.propertyGroupId
                );
                if (g) {
                  setSelectedPropertyOptions([optionInUnselectedGroup]);
                  setSelectedPropertyGroups([g]);
                }
              } else {
                setSelectedPropertyOptions(options);
              }
            }}
            selectedPropertyGroups={selectedPropertyGroups}
            updateSelectedPropertyGroups={(groups) => {
              setSelectedPropertyGroups(groups.slice(-1));
              setSelectedPropertyOptions([]);
            }}
            optionsDisabled={request.isUserInputAllowed}
            optionsDisabledMessage={t('properties_disabled')}
            withSearch
            maxHeight={'20vh'}
          />
        </div>
      ) : (
        <LoadingContainer />
      )}
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Check
            text={t('isUserInputAllowed')}
            checked={request.isUserInputAllowed}
            update={() => {
              const bool = !request.isUserInputAllowed;
              update({
                ...request,
                isUserInputAllowed: bool,
                propertyValidationRuleIds: null,
              });
              setSelectedPropertyOptions([]);
            }}
          />
        </div>
      </div>
      {request.isUserInputAllowed ? (
        <>
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <ToggleSwitch
                title={t('hasPropertyOptionListing')}
                toggled={request.hasPropertyOptionListing}
                toggle={() =>
                  update({
                    ...request,
                    hasPropertyOptionListing: !request.hasPropertyOptionListing,
                  })
                }
              />
            </div>
          </div>
          <PropertyValidationRuleArray
            title={t('propertyValidationRuleIds.title')}
            availablePropertyValidationRules={availablePropertyValidationRules}
            request={request}
            update={update}
            refreshAvailablePropertyValidationRules={
              refreshAvailablePropertyValidationRules
            }
          />
        </>
      ) : null}
    </div>
  );
};

export default ProductGroupMappingEdit;
