import { useTranslation } from 'react-i18next';
import { Stage, Navigator } from '../../layout/stage/Stage';
import Card, {
  CardSection,
  Tab,
  TabConfig,
  Tabs,
} from '../../elements/card/Card';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';
import { default as Config } from '../../sections/admin/categories/Categories';
import { useUser } from '../../contexts/auth/User';
import CategoryPropertyMatrix from '../../sections/admin/categories/categorypropertymatrix/CategoryPropertyMatrix';

const Categories = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.productCategories',
  });
  const { user } = useUser();

  const tabs: TabConfig[] = [
    {
      key: 'hierarchy',
      title: t('tabs.hierarchy'),
    },
    {
      key: 'matrix',
      title: t('tabs.matrix'),
    },
  ];

  if (user?.isProductOwner) {
    return (
      <Stage>
        <Navigator title={t('title')}></Navigator>
        <Tabs tabs={tabs} look={'card'}>
          <Tab>
            <Card bigScreenWidth={'100%'}>
              <Config />
            </Card>
          </Tab>
          <Tab>
            <Card bigScreenWidth={'100%'}>
              <CardSection>
                <CategoryPropertyMatrix />
              </CardSection>
            </Card>
          </Tab>
        </Tabs>
      </Stage>
    );
  } else {
    return <NoAuthorization />;
  }
};

export default Categories;
