import './categorypropertymatrixrow.css';
import { PropertyGroupResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';
import { ReactComponent as IconProduct } from '../../../../../../assets/icon/layer_single.svg';
import _ from 'lodash';

interface CategoryPropertyMatrixRowProps {
  title?: string | null;
  properties?: PropertyGroupResponse[] | null;
  productGroupId?: string;
  backgroundColor?: string;
}

const CategoryPropertyMatrixRow: React.FC<CategoryPropertyMatrixRowProps> = ({
  title,
  properties,
  productGroupId,
  backgroundColor,
}) => {
  const { i18n } = useTranslation();

  // get the indentation in pixels based on the opacity on the alpha value of the background color
  const indentationOffset = backgroundColor?.match(/\d+\.?\d*/g)?.[3];
  const indentationPx =
    100 - (indentationOffset ? parseFloat(indentationOffset) * 100 : 100);

  // sort properties alphabetically
  const getPropertiesSorted = (properties: PropertyGroupResponse[]) => {
    return _.sortBy(properties, (property) => {
      return property.name[i18n.language as TranslatedStringIndex];
    });
  };

  return (
    <tr className="categoryPropertyMatrixRow">
      <td className="categoryPropertyMatrixRow-title categoryPropertyMatrix-table-cell">
        <div
          className={'categoryPropertyMatrix-table-cell-content'}
          style={{
            backgroundColor: backgroundColor,
            paddingLeft: 8 + indentationPx,
          }}
        >
          {productGroupId ? (
            <IconProduct
              className={'categoryPropertyMatrixRow-title-indicator'}
            />
          ) : null}
          {title}
        </div>
      </td>
      {properties === null ? (
        <td className={'categoryPropertyMatrixRow-groups-loader'}>...</td>
      ) : properties ? (
        getPropertiesSorted(properties).map((property) => {
          return (
            <td
              className={
                'categoryPropertyMatrix-table-cell categoryPropertyMatrixRow-group'
              }
            >
              <div
                className={'categoryPropertyMatrix-table-cell-content'}
                style={{ backgroundColor: backgroundColor }}
              >
                {property.name[i18n.language as TranslatedStringIndex]}
              </div>
            </td>
          );
        })
      ) : undefined}
    </tr>
  );
};

export default CategoryPropertyMatrixRow;
