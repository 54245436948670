import './properties.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  AnimalSpeciesResponse,
  ProductGroupMappingResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import { CardTitle } from '../../../elements/card/Card';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Popup from '../../../elements/popup/Popup';
import PropertyBrowser from '../../../features/propertybrowser/PropertyBrowser';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import usePersistInCookies from '../../../hooks/useStorageData';
import useNotifications from '../../../hooks/useNotifications';
import useUserTools from '../../../hooks/useUserTools';
import PropertiesList from './propertieslist/PropertiesList';

interface PropertiesProps {
  productId?: string;
  productVersionId?: string;
  inheritedProductProperties?: PropertyGroupResponse[] | null;
  callback?: (options: PropertyOptionResponse[]) => void;
  readonly?: boolean;
  toggleProductSavingHelpOverlay?: () => void;
  productGroupMappings: ProductGroupMappingResponse[];
  selectedAnimalSpecies?: AnimalSpeciesResponse[];
  validationOptional?: boolean;
  availableProductProperties: PropertyGroupResponse[];
}

const Properties: React.FC<PropertiesProps> = ({
  productId,
  productVersionId,
  inheritedProductProperties,
  callback,
  readonly,
  toggleProductSavingHelpOverlay,
  productGroupMappings,
  selectedAnimalSpecies,
  validationOptional,
  availableProductProperties,
}) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);
  const api = usePetCloudApi();
  const propertyGroupsApi = api.propertyGroupsApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { excludeUnlockedOptions } = useUserTools();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [productProperties, setProductProperties] = useState<
    PropertyGroupResponse[] | null
  >(null);
  const [selectedPropertyOptions, setSelectedPropertyOptions] = useState<
    PropertyOptionResponse[]
  >([]);
  const [originalSelectedPropertyOptions, setOriginalSelectedPropertyOptions] =
    useState<PropertyOptionResponse[]>([]);
  const [productVariantOptions, setProductVariantOptions] = useState<
    PropertyOptionResponse[] | null | undefined
  >(null);
  const { persistObject, getPersistedObject } = usePersistInCookies();

  useEffect(() => {
    getProductProperties();
    getProductVariantOptions();
  }, [productVersionId]);

  const getProductProperties = () => {
    if (productId) {
      propertyGroupsApi
        .propertyGroupsGetPropertyGroups(
          productId,
          true,
          excludeUnlockedOptions(),
          productVersionId
        )
        .then((response) => {
          console.log(response);
          setProductProperties(response.data);
          getSelectedPropertyOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      setProductProperties([]);
    }
  };

  useEffect(() => {
    if (window.location.href.includes('products/new')) {
      const persisted = getPersistedObject('new_product_properties');
      setSelectedPropertyOptions(persisted ?? []);
      if (persisted && callback) {
        callback(persisted as PropertyOptionResponse[]);
        updateProductProperties(persisted);
      }
    }
  }, [availableProductProperties]);

  const getSelectedPropertyOptions = (
    propertyGroups: PropertyGroupResponse[]
  ) => {
    const selectedOptions: PropertyOptionResponse[] = [];
    propertyGroups.forEach((group) => {
      group.options.forEach((option) => {
        selectedOptions.push(option);
      });
    });

    // when inheriting properties, transfer them over to the selectedProperties as basis for changing them
    // only transfer them if there are no "own" properties set already
    if (selectedOptions.length === 0) {
      inheritedProductProperties?.forEach((group) => {
        group.options.forEach((option) => {
          selectedOptions.push(option);
        });
      });
    }
    setSelectedPropertyOptions(selectedOptions);
    setOriginalSelectedPropertyOptions(selectedOptions);
  };

  const updateSelectedProperties = (options: PropertyOptionResponse[]) => {
    setSelectedPropertyOptions(options);
    if (callback) {
      callback([...options]);
      persistObject(options, 'new_product_properties');
    }
  };

  const getProductVariantOptions = () => {
    if (productId) {
      productsApi
        .productsGetProductById(productId, true, undefined, productVersionId)
        .then((response) => {
          console.log(response);
          setProductVariantOptions(response.data.variantOptions);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const submit = () => {
    if (productId) {
      submitUpdatedProductProperties();
    } else {
      updateProductProperties(selectedPropertyOptions);
    }
  };

  const updateProductProperties = (selection: PropertyOptionResponse[]) => {
    if (availableProductProperties) {
      const update: PropertyGroupResponse[] = [];
      selection.forEach((property) => {
        const i = update.findIndex(
          (group) => group.id === property.propertyGroupId
        );
        if (i !== -1) {
          update[i].options.push(property);
        } else {
          const group = availableProductProperties.find(
            (group) => group.id === property.propertyGroupId
          );
          if (group) {
            const newGroup = { ...group };
            newGroup.options = [property];
            update.push(newGroup);
          }
        }
      });
      setProductProperties([...update]);
    }
  };

  const submitUpdatedProductProperties = () => {
    if (productId) {
      setIsSubmitting(true);
      const propertyOptionIds = selectedPropertyOptions.map((property) => {
        return property.id;
      });
      productsApi
        .productsUpdateProductProperties(productId, {
          propertyOptionIds: propertyOptionIds,
        })
        .then((response) => {
          console.log(response);
          pushNotification(
            t('view.product.notifications.propertiesUpdate_successful')
          );
          setIsSubmitting(false);
          getProductProperties();
          if (toggleProductSavingHelpOverlay) {
            toggleProductSavingHelpOverlay();
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  if (availableProductProperties && productProperties) {
    return (
      <div className="properties">
        <div className="properties-title">
          <CardTitle
            title={t('view.product.properties.title')}
            inherited={
              inheritedProductProperties !== undefined
                ? productProperties && productProperties.length === 0
                : undefined
            }
            hasBottomMargin
            productGroupMappings={productGroupMappings}
          />
        </div>
        {inheritedProductProperties && productProperties.length === 0 ? (
          <div className={'properties-inherited'}>
            <PropertiesList
              availablePropertyGroups={availableProductProperties}
              propertyGroups={inheritedProductProperties}
              productGroupMappings={productGroupMappings}
              validationOptional={validationOptional}
            />
          </div>
        ) : (
          <PropertiesList
            availablePropertyGroups={availableProductProperties}
            propertyGroups={productProperties}
            productGroupMappings={productGroupMappings}
            validationOptional={validationOptional}
          />
        )}
        {!readonly ? (
          <div className="properties-actions">
            <Button
              look={'secondary'}
              cta={t('view.product.properties.edit')}
              action={() => setPopup(!popup)}
              width="minimal"
              isLoading={isSubmitting}
              active={!isSubmitting && !readonly}
            />
          </div>
        ) : null}
        <Popup toggled={popup} width="50%" close={() => setPopup(false)}>
          <div className="popup-title">
            {t('view.product.properties.popup.title')}
          </div>
          <PropertyBrowser
            availablePropertyGroups={availableProductProperties}
            selectedPropertyOptions={selectedPropertyOptions}
            blockedPropertyOptions={productVariantOptions}
            updateSelectedPropertyOptions={updateSelectedProperties}
            productGroupMappings={productGroupMappings}
            selectedAnimalSpeciesIds={selectedAnimalSpecies?.map((x) => x.id)}
            validationOptional={validationOptional}
            withSearch
          />
          <div className="properties-popup-actions global-cardActions">
            <Button
              cta={t('actions.save')}
              action={() => {
                submit();
                setPopup(false);
              }}
              look="save"
              width="minimal"
              active={
                selectedPropertyOptions !== originalSelectedPropertyOptions &&
                !readonly
              }
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Properties;
