import './brandshare.css';
import {
  Cell,
  Pie,
  PieChart,
  PieLabelRenderProps,
  ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';

const BrandShare = () => {
  const { t } = useTranslation();

  const brandShareData = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
    { name: 'Group E', value: 278 },
    { name: 'Group F', value: 189 },
  ];

  const brands = [
    'HUNTER',
    'mera',
    'Cr.Clauder´s',
    'Carnello',
    'Fred & Felia',
    'Sollis',
  ];

  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#AF19FF',
    '#FF1919',
  ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: PieLabelRenderProps) => {
    const RADIAN = Math.PI / 180;
    const radius =
      ((innerRadius as number) ?? 0) +
      (((outerRadius as number) ?? 0) - ((innerRadius as number) ?? 0)) * 0.5;
    const x = (cx as number) + radius * (Math.cos(-midAngle * RADIAN) * 1.5);
    const y = (cy as number) + radius * (Math.sin(-midAngle * RADIAN) * 1.5);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={cx && x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${((percent ?? 0) * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className={'brandShare'}>
      <div className={'brandShare-text'}>
        {t('view.statistics.brandShare.text')}
      </div>
      <div className={'brandShare-stage'}>
        <div className={'brandShare-brands'}>
          {brands.map((brand, index) => (
            <div className={'brandShare-brand'}>
              <div
                className={'brandShare-brand-bubble'}
                style={{ backgroundColor: COLORS[index % COLORS.length] }}
              ></div>
              <div className={'brandShare-brand-name'}>{brand}</div>
            </div>
          ))}
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart height={340}>
            <Pie
              dataKey="value"
              data={brandShareData}
              cx="50%"
              cy="50%"
              outerRadius={140}
              fill="#8884d8"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {brandShareData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BrandShare;
