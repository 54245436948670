import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';

const useSortValueFunctions = () => {
  const { i18n } = useTranslation();

  const translatedValue = (item: any, key: string) => {
    return item[key][i18n.language as TranslatedStringIndex]?.toLowerCase();
  };

  const simpleValue = (item: any, key: string) => {
    console.log(key, item[key]?.toLowerCase());
    return item[key]?.toLowerCase();
  };

  return {
    translatedValue,
    simpleValue,
  };
};

export default useSortValueFunctions;
