import {
  AnimalSpeciesResponse,
  CreateProductGroupRequest,
  PropertyGroupResponse,
  PropertyValidationRuleResponse,
  UpdateProductGroupRequest,
} from '../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import Input from '../../../../elements/input/Input';
import AnimalSpeciesArray from '../../animalspecies/animalspeciesarray/AnimalSpeciesArray';
import PropertyValidationRuleArray from '../../propertyvalidationrules/propertyvalidationrulearray/PropertyValidationRuleArray';
import PropertyGroupArray from '../propertygrouparray/PropertyGroupArray';

interface ProductGroupEditProps {
  group: UpdateProductGroupRequest | CreateProductGroupRequest;
  setProductGroup: (
    group: UpdateProductGroupRequest | CreateProductGroupRequest
  ) => void;
  animalSpecies: AnimalSpeciesResponse[] | null | undefined;
  availableAnimalSpecies: AnimalSpeciesResponse[];
  availablePropertyValidationRules: PropertyValidationRuleResponse[];
  availablePropertyGroups: PropertyGroupResponse[];
  refreshAvailablePropertyGroups: () => void;
  refreshAvailablePropertyValidationRules: () => void;
}

const ProductGroupEdit: React.FC<ProductGroupEditProps> = ({
  group,
  setProductGroup,
  animalSpecies,
  availableAnimalSpecies,
  availablePropertyValidationRules,
  availablePropertyGroups,
  refreshAvailablePropertyGroups,
  refreshAvailablePropertyValidationRules,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.productGroups.newPopup',
  });
  return (
    <div className="productgroups-groupForm">
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('identifier')}
            content={group.identifier}
            update={(value) => {
              setProductGroup({
                ...group,
                identifier: value,
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('nameDE')}
            content={group.name['de-DE']}
            update={(value) => {
              setProductGroup({
                ...group,
                name: {
                  ...group.name,
                  'de-DE': value,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('nameEN')}
            content={group.name['en-GB']}
            update={(value) => {
              setProductGroup({
                ...group,
                name: {
                  ...group.name,
                  'en-GB': value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('descriptionDE')}
            content={group.description ? group.description['de-DE'] : null}
            update={(value) => {
              setProductGroup({
                ...group,
                description: {
                  ...group.description,
                  'de-DE': value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('descriptionEN')}
            content={group.description ? group.description['en-GB'] : null}
            update={(value) => {
              setProductGroup({
                ...group,
                description: {
                  ...group.description,
                  'en-GB': value,
                },
              });
            }}
          />
        </div>
      </div>
      <PropertyValidationRuleArray
        title={t('additionalValidationRules')}
        availablePropertyValidationRules={availablePropertyValidationRules}
        request={group}
        update={setProductGroup}
        refreshAvailablePropertyValidationRules={
          refreshAvailablePropertyValidationRules
        }
      />
      <AnimalSpeciesArray
        animalSpecies={animalSpecies}
        availableAnimalSpecies={availableAnimalSpecies}
        onDelete={(index) => {
          let update = group.animalSpeciesIds;
          if (update) {
            update.splice(index, 1);
          }
          setProductGroup({
            ...group,
            animalSpeciesIds: update,
          });
        }}
        onSubmit={(animalSpecies) => {
          let update = group.animalSpeciesIds;
          if (update) {
            update.push(animalSpecies.id);
          } else {
            update = [animalSpecies.id];
          }
          setProductGroup({
            ...group,
            animalSpeciesIds: update,
          });
        }}
      />
      <PropertyGroupArray
        propertyGroupIds={group.propertyGroupIds}
        availablePropertyGroups={availablePropertyGroups}
        updateSelectedPropertyGroups={(groups) => {
          setProductGroup({
            ...group,
            propertyGroupIds: groups.map((g) => g.id),
          });
        }}
        refreshAvailablePropertyGroups={refreshAvailablePropertyGroups}
      />
    </div>
  );
};

export default ProductGroupEdit;
