import './productgroupmappings.css';
import {
  ProductGroupMappingResponse,
  ProductGroupResponse,
  PropertyGroupResponse,
  PropertyValidationRuleResponse,
  UpdateProductGroupMappingRequest,
} from '../../../api/petcloudapi/api';
import List from '../../../features/list/List';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import useListRenderObjects from '../../../hooks/list/useListRenderObjects';
import Badge from '../../../elements/badge/Badge';
import { useState } from 'react';
import Popup from '../../../elements/popup/Popup';
import ProductGroupMappingEdit from './productgroupmappingedit/ProductGroupMappingEdit';
import InformationBox from '../../../elements/informationbox/InformationBox';
import Button from '../../../elements/button/Button';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../hooks/useNotifications';
import useListControlsSearch from '../../../features/list/listcontrols/utils/useListControlsSearch';
import Hoverable from '../../../elements/hoverable/Hoverable';

interface ProductGroupMappingsProps {
  productGroupMappings: ProductGroupMappingResponse[];
  availableProductGroups: ProductGroupResponse[];
  availablePropertyValidationRules: PropertyValidationRuleResponse[];
  refreshAvailablePropertyValidationRules: () => void;
  refreshMappings: () => void;
}

const ProductGroupMappings: React.FC<ProductGroupMappingsProps> = ({
  productGroupMappings,
  availableProductGroups,
  availablePropertyValidationRules,
  refreshAvailablePropertyValidationRules,
  refreshMappings,
}) => {
  const { i18n, t } = useTranslation('translations', {
    keyPrefix: 'view.admin.productGroupMappings',
  });
  const api = usePetCloudApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { renderBoolean, renderArrayCount } = useListRenderObjects();
  const { listControlSearch, currentItems, query } = useListControlsSearch(
    t('search'),
    productGroupMappings,
    ['name']
  );

  const [mappingToEdit, setMappingToEdit] =
    useState<UpdateProductGroupMappingRequest | null>(null);
  const [mappingToDelete, setMappingToDelete] =
    useState<ProductGroupMappingResponse | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const renderPropertyGroup = (group: PropertyGroupResponse) => {
    return <div>{group.name[i18n.language as TranslatedStringIndex]}</div>;
  };

  const renderProductGroups = (groups: ProductGroupResponse[]) => {
    return (
      <div className={'productGroupMappings-groups'}>
        <Hoverable
          onHoverNode={
            <div className={'productGroupMappings-groups-names'}>
              {groups.map((g, i) => {
                return (
                  <div key={i} className={'productGroupMappings-groups-name'}>
                    {g.name[i18n.language as TranslatedStringIndex]}
                  </div>
                );
              })}
            </div>
          }
        >
          <Badge title={groups.length.toString()} color={'var(--color-blue)'} />
        </Hoverable>
      </div>
    );
  };

  const initEdit = (mapping: ProductGroupMappingResponse) => {
    const productGroupIds = mapping.productGroups?.map((x) => x.id);
    const propertyOptionIds = mapping.propertyOptions?.map((x) => x.id);
    const propertyValidationRuleIds = mapping.propertyValidationRules?.map(
      (x) => x.id
    );
    setMappingToEdit({
      ...mapping,
      productGroupIds: productGroupIds,
      propertyOptionIds:
        !mapping.isUserInputAllowed &&
        propertyOptionIds &&
        propertyOptionIds.length > 0
          ? propertyOptionIds
          : null,
      propertyValidationRuleIds: propertyValidationRuleIds,
    });
  };

  const submitEditedMapping = (mapping: UpdateProductGroupMappingRequest) => {
    setIsSubmitting(true);
    productGroupMappingsApi
      .productGroupMappingsUpdateProductGroupMapping(
        (mapping as ProductGroupMappingResponse).id,
        mapping
      )
      .then((response) => {
        console.log(response);
        setMappingToEdit(null);
        refreshMappings();
        pushNotification(t('edit.notifications.success'));
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const deleteMapping = (mapping: ProductGroupMappingResponse) => {
    setIsDeleting(true);
    productGroupMappingsApi
      .productGroupMappingsDeleteProductGroupMapping(mapping.id)
      .then((response) => {
        console.log(response);
        setMappingToDelete(null);
        refreshMappings();
        pushNotification(t('delete.notifications.success'));
        setIsDeleting(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsDeleting(false);
      });
  };

  return (
    <div className={'productGroupMappings'}>
      {productGroupMappings.length > 0 ? (
        <>
          <List
            items={currentItems}
            ignore={['id', 'updatedAt', 'propertyGroupId', 'description']}
            dateStrings={['createdAt']}
            queryString={query}
            queryKeys={['name']}
            renderObjects={[
              {
                key: 'propertyGroup',
                renderMethod: renderPropertyGroup,
              },
              {
                key: 'isOptional',
                renderMethod: renderBoolean,
              },
              {
                key: 'isUserInputAllowed',
                renderMethod: renderBoolean,
              },
              {
                key: 'propertyOptions',
                renderMethod: renderArrayCount,
              },
              {
                key: 'propertyValidationRules',
                renderMethod: renderArrayCount,
              },
              {
                key: 'hasPropertyOptionListing',
                renderMethod: renderBoolean,
              },
              {
                key: 'productGroups',
                renderMethod: renderProductGroups,
              },
            ]}
            actions={[
              {
                cta: 'edit',
                look: 'blue',
                action: (item) => initEdit(item),
              },
              {
                cta: 'delete',
                look: 'danger',
                action: (item) => setMappingToDelete(item),
              },
            ]}
            onRowClick={(item) => initEdit(item)}
            listControls={{
              search: listControlSearch,
            }}
            tableHeadContrast
            adjustHeightToViewport
          />
          {mappingToEdit ? (
            <Popup
              toggled={true}
              width={'50%'}
              close={() => setMappingToEdit(null)}
            >
              <ProductGroupMappingEdit
                request={mappingToEdit}
                update={(r) => setMappingToEdit(r)}
                availableProductGroups={availableProductGroups}
                availablePropertyValidationRules={
                  availablePropertyValidationRules
                }
                refreshAvailablePropertyValidationRules={
                  refreshAvailablePropertyValidationRules
                }
              />
              <div
                className={'global-cardActions productGroupMappings-actions'}
              >
                <Button
                  cta={t('edit.cta')}
                  look={'save'}
                  action={() => submitEditedMapping(mappingToEdit)}
                  isLoading={isSubmitting}
                  width={'minimal'}
                />
              </div>
            </Popup>
          ) : null}
          {mappingToDelete ? (
            <Popup
              toggled={true}
              width={'15%'}
              close={() => setMappingToDelete(null)}
            >
              <div className={'popup-title'}>{t('delete.title')}</div>
              <InformationBox content={t('delete.text')} type={'warning'} />
              <Button
                cta={t('delete.cta')}
                look={'danger'}
                width={'full'}
                action={() => deleteMapping(mappingToDelete)}
                isLoading={isDeleting}
              />
            </Popup>
          ) : null}
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default ProductGroupMappings;
