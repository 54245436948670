import './categoryanalysis.css';
import List from '../../../../features/list/List';
import useListRenderObjects from '../../../../hooks/list/useListRenderObjects';

const CategoryAnalysis = () => {
  const { renderPercentageBar, renderValueComparison } = useListRenderObjects();

  const data = [
    {
      name: 'Nassfutter',
      revenuePercentage: 44,
      orderPercentage: 49,
      revenueGrowth: 27,
      popularity: 20,
      productCount: 278,
      productGrowth: 12,
    },
    {
      name: 'Snacks',
      revenuePercentage: 25,
      orderPercentage: 66,
      revenueGrowth: 24,
      popularity: 30,
      productCount: 244,
      productGrowth: 16,
    },
    {
      name: 'Leinen Halsbänder & Geschirre',
      revenuePercentage: 8,
      orderPercentage: 12,
      revenueGrowth: 25,
      popularity: 24,
      productCount: 65,
      productGrowth: 14,
    },
    {
      name: 'Zubehör',
      revenuePercentage: 14,
      orderPercentage: 16,
      revenueGrowth: 26,
      popularity: 30,
      productCount: 207,
      productGrowth: 17,
    },
    {
      name: 'Spielzeug',
      revenuePercentage: 2.5,
      orderPercentage: 5,
      revenueGrowth: 18,
      popularity: 17,
      productCount: 10,
      productGrowth: 15,
    },
    {
      name: 'Angebote',
      revenuePercentage: 6.5,
      orderPercentage: 12,
      revenueGrowth: 20,
      popularity: 28,
      productCount: 31,
      productGrowth: 14,
    },
  ];

  return (
    <div className={'categoryAnalysis'}>
      <List
        items={data}
        renderObjects={[
          {
            key: 'revenuePercentage',
            renderMethod: renderPercentageBar,
          },
          {
            key: 'orderPercentage',
            renderMethod: renderPercentageBar,
          },
          {
            key: 'revenueGrowth',
            renderMethod: (v) => renderValueComparison(v, 24),
          },
          {
            key: 'productGrowth',
            renderMethod: (v) => renderValueComparison(v, 15),
          },
          {
            key: 'popularity',
            renderMethod: (v) => renderValueComparison(v, 16),
          },
        ]}
      />
    </div>
  );
};

export default CategoryAnalysis;
