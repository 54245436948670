import './categorypropertymatrixgroup.css';
import {
  ProductCategoryResponse,
  ProductGroupMappingResponse,
  ProductGroupResponse,
  PropertyGroupResponse,
} from '../../../../../api/petcloudapi/api';
import CategoryPropertyMatrixRow from '../categorypropertymatrixrow/CategoryPropertyMatrixRow';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';
import useDepthColoring from '../hooks/useDepthColoring';
import { useEffect, useState } from 'react';
import _ from 'lodash';

interface CategoryPropertyMatrixGroupProps {
  availableProductGroups: ProductGroupResponse[];
  category: ProductCategoryResponse;
  depth: number;
  index: number;
  categoryColor?: string;
  updateParentProperties?: (
    props: PropertyGroupResponse[],
    isLeafCategory: boolean
  ) => void;
  productGroupMappings?: ProductGroupMappingResponse[] | null;
  isShowOnlyRequired?: boolean;
  isHideProductGroups?: boolean;
}

const CategoryPropertyMatrixGroup: React.FC<
  CategoryPropertyMatrixGroupProps
> = ({
  availableProductGroups,
  category,
  depth,
  index,
  categoryColor,
  updateParentProperties,
  productGroupMappings,
  isShowOnlyRequired,
  isHideProductGroups,
}) => {
  const { i18n } = useTranslation();
  const { getDepthColor, reduceOpacity } = useDepthColoring();

  const [properties, setProperties] = useState<PropertyGroupResponse[] | null>(
    null
  );

  useEffect(() => {
    if (updateParentProperties && properties)
      updateParentProperties(properties, false);
  }, [properties]);

  useEffect(() => {
    category.productGroups?.forEach((productGroup) => {
      let props = availableProductGroups?.find(
        (x) => x.id === productGroup.id
      )?.propertyGroups;
      if (props) {
        if (isShowOnlyRequired) {
          props = props.filter((propertyGroup) => {
            const mapping = productGroupMappings?.find(
              (x) =>
                x.propertyGroupId === propertyGroup.id &&
                !!x.productGroups?.find((y) => y.id === productGroup.id)
            );
            if (!mapping) {
              return false;
            } else {
              return !mapping.isOptional;
            }
          });
        }
        updateProperties(props, true);
      }
    });
  }, [
    category.productGroups,
    availableProductGroups,
    productGroupMappings,
    isShowOnlyRequired,
  ]);

  const updateProperties = (
    props: PropertyGroupResponse[],
    isLeafCategory: boolean
  ) => {
    if (isLeafCategory) {
      // if this is a leaf node, set its properties without filtering
      setProperties((previousProperties) => props);
    } else {
      // if this isn't a leaf category
      if (!properties) {
        // and there haven't been any properties set yet, also set the properties without filtering
        setProperties((previousProperties) => props);
      } else {
        // and there have been properties already set by another component,
        // set the properties to be the result of an intersection between the current properties and the new ones
        setProperties((previousProperties) =>
          _.intersectionBy(previousProperties, props, 'id')
        );
      }
    }
  };

  if (!categoryBlacklist.includes(category.identifier)) {
    let color = getDepthColor(categoryColor, depth, index);
    return (
      <>
        <CategoryPropertyMatrixRow
          key={category.id}
          title={category.name[i18n.language as TranslatedStringIndex]}
          backgroundColor={color}
          properties={properties}
        />
        {category.children && category.children.length > 0
          ? category.children.map((c, i) => (
              <CategoryPropertyMatrixGroup
                key={c.id}
                availableProductGroups={availableProductGroups}
                category={c}
                depth={depth + 1}
                index={i}
                categoryColor={color}
                updateParentProperties={updateProperties}
                productGroupMappings={productGroupMappings}
                isShowOnlyRequired={isShowOnlyRequired}
                isHideProductGroups={isHideProductGroups}
              />
            ))
          : !isHideProductGroups
          ? category.productGroups?.map((productGroup) => {
              return (
                <CategoryPropertyMatrixRow
                  key={productGroup.id}
                  title={
                    productGroup.name[i18n.language as TranslatedStringIndex]
                  }
                  properties={properties}
                  productGroupId={productGroup.id}
                  backgroundColor={color ? reduceOpacity(color, 20) : undefined}
                />
              );
            })
          : null}
      </>
    );
  } else {
    return null;
  }
};

export default CategoryPropertyMatrixGroup;

const categoryBlacklist = ['internal_brands', 'internal_magazine'];
