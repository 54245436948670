import './propertiessearch.css';
import SearchablePropertyOptionResponse from '../../../types/SearchablePropertyOptionResponse';
import {
  ProductGroupResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
} from '../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Searchbar from '../../../elements/searchbar/Searchbar';
import { queryItems } from '../../../elements/productsearch/ProductSearch';
import { Check } from '../../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { Tab, Tabs } from '../../../elements/card/Card';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import ClickOutsideContainer from '../../../elements/clickoutsidecontianer/ClickOutsideContainer';

interface PropertiesSearchProps {
  searchablePropertyOptions: SearchablePropertyOptionResponse[];
  propertyGroups: ProductGroupResponse[];
  selectedPropertyOptions?: PropertyOptionResponse[];
  selectedPropertyGroups?: ProductGroupResponse[];
  updateSelectedProperties?: (options: PropertyOptionResponse[]) => void;
  selectPropertyGroup: (group: PropertyGroupResponse) => void;
  optionsOnly?: boolean;
  invertedColor?: boolean;
  cta: string;
}

const PropertiesSearch: React.FC<PropertiesSearchProps> = ({
  searchablePropertyOptions,
  propertyGroups,
  selectedPropertyOptions,
  selectedPropertyGroups,
  updateSelectedProperties,
  selectPropertyGroup,
  optionsOnly,
  invertedColor,
  cta,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'components.propertyBrowser',
  });
  const [optionSearchResults, setOptionSearchResults] = useState<
    SearchablePropertyOptionResponse[]
  >([]);
  const [groupSearchResults, setGroupSearchResults] = useState<
    PropertyGroupResponse[]
  >([]);
  const [currentQuery, setCurrentQuery] = useState('');

  const renderOptionResults = () => {
    if (
      selectedPropertyOptions &&
      updateSelectedProperties &&
      optionSearchResults.length > 0
    ) {
      return optionSearchResults.map((result) => {
        return (
          <div
            className="propertiesSearch-result"
            onClick={() => {
              const update = [...selectedPropertyOptions];
              const i = update.findIndex((o) => o.id === result.id);
              if (i !== -1) {
                update.splice(i, 1);
              } else {
                update.push(result);
              }
              updateSelectedProperties(update);
            }}
          >
            <div className="propertiesSearch-result-check">
              <Check
                checked={
                  !!selectedPropertyOptions.find((p) => p.id === result.id)
                }
              />
            </div>
            <div className="propertiesSearch-result-info">
              <div className="propertiesSearch-result-info-heading">
                {result.groupName[i18n.language as TranslatedStringIndex]}
              </div>
              <div className="propertiesSearch-result-info-name">
                {result.name[i18n.language as TranslatedStringIndex]}
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="propertiesSearch-results-emptyState">
          <EmptyState message={t('searchResultsTabs.empty')} />
        </div>
      );
    }
  };

  return (
    <div className="propertiesSearch">
      <Searchbar
        cta={cta}
        height="normal"
        invertedColor={invertedColor}
        onDebouncedSearch={(input) => {
          queryItems(
            input,
            searchablePropertyOptions,
            (q) => {
              setCurrentQuery(q);
            },
            (r) => {
              setOptionSearchResults(r);
            },
            (item: any, lowercasedQuery) => {
              const result = item.name['de-DE']
                ?.toLocaleLowerCase()
                .includes(lowercasedQuery);
              console.log(item, lowercasedQuery, result);
              return result;
            }
          );
          queryItems(
            input,
            propertyGroups,
            (q) => {
              setCurrentQuery(q);
            },
            (r) => {
              setGroupSearchResults(r);
            },
            (item: any, lowercasedQuery) => {
              return item.name['de-DE']
                ?.toLocaleLowerCase()
                .includes(lowercasedQuery);
            }
          );
        }}
      />
      {currentQuery ? (
        <ClickOutsideContainer onClickOutside={() => setCurrentQuery('')}>
          <div className={'propertiesSearch-results-wrapper'}>
            {optionsOnly ? (
              <div className="propertiesSearch-results">
                {renderOptionResults()}
              </div>
            ) : (
              <Tabs
                tabs={[
                  {
                    title: t('searchResultsTabs.groups'),
                    key: 'groups',
                    badge: {
                      title: groupSearchResults.length.toString(),
                      color: 'var(--color-text_secondary)',
                    },
                  },
                  {
                    title: t('searchResultsTabs.options'),
                    key: 'options',
                    badge: {
                      title: optionSearchResults.length.toString(),
                      color: 'var(--color-text_secondary)',
                    },
                  },
                ]}
                dontSetHash
              >
                <Tab>
                  <div className="propertiesSearch-results">
                    {groupSearchResults.length > 0 ? (
                      groupSearchResults.map((result) => {
                        const isChecked = !!selectedPropertyGroups?.find(
                          (p) => p.id === result.id
                        );
                        return (
                          <div
                            className="propertiesSearch-result"
                            onClick={() => selectPropertyGroup(result)}
                          >
                            {selectedPropertyGroups ? (
                              <div className="propertiesSearch-result-check">
                                <Check checked={isChecked} />
                              </div>
                            ) : null}
                            <div className="propertiesSearch-result-info">
                              <div className="propertiesSearch-result-info-name">
                                {
                                  result.name[
                                    i18n.language as TranslatedStringIndex
                                  ]
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="propertiesSearch-results-emptyState">
                        <EmptyState message={t('searchResultsTabs.empty')} />
                      </div>
                    )}
                  </div>
                </Tab>
                <Tab>
                  <div className="propertiesSearch-results">
                    {renderOptionResults()}
                  </div>
                </Tab>
              </Tabs>
            )}
          </div>
        </ClickOutsideContainer>
      ) : null}
    </div>
  );
};

export default PropertiesSearch;
