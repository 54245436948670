import './petprofiles.css';
import { Tab, TabConfig, Tabs } from '../../../../elements/card/Card';
import BarChart from '../../common/charts/barchart/BarChart';
import { ReactComponent as IconFilter } from '../../../../../assets/icon/filter.svg';
import { ReactComponent as IconClose } from '../../../../../assets/icon/close.svg';

const PetProfiles = () => {
  const dataAvgAge = [
    {
      age: 1,
      amount: 123,
    },
    {
      age: 2,
      amount: 169,
    },
    {
      age: 3,
      amount: 255,
    },
    {
      age: 4,
      amount: 401,
    },
    {
      age: 5,
      amount: 689,
    },
    {
      age: 6,
      amount: 1024,
    },
    {
      age: 7,
      amount: 900,
    },
    {
      age: 8,
      amount: 642,
    },
    {
      age: 9,
      amount: 850,
    },
    {
      age: 10,
      amount: 603,
    },
    {
      age: 11,
      amount: 418,
    },
    {
      age: 12,
      amount: 202,
    },
    {
      age: 13,
      amount: 89,
    },
  ];

  const dataAvgWeight = [
    {
      weight: '< 5',
      amount: 23,
    },
    {
      weight: '6 - 10',
      amount: 145,
    },
    {
      weight: '11 - 20',
      amount: 679,
    },
    {
      weight: '21 - 30',
      amount: 987,
    },
    {
      weight: '31 - 40',
      amount: 926,
    },
    {
      weight: '41 - 50',
      amount: 731,
    },
    {
      weight: '51 - 60',
      amount: 496,
    },
    {
      weight: '> 60',
      amount: 108,
    },
  ];

  const dataSex = [
    {
      sex: 'Männlich',
      amount: 2475,
    },
    {
      sex: 'Weiblich',
      amount: 1873,
    },
  ];

  const dataActivity = [
    {
      activity: 'Inaktiv',
      amount: 204,
    },
    {
      activity: 'Mäßig aktiv',
      amount: 689,
    },
    {
      activity: 'Aktiv',
      amount: 2480,
    },
    {
      activity: 'Übermäßig Aktiv',
      amount: 1011,
    },
  ];

  const dataHousing = [
    {
      housing: 'Drinnen',
      amount: 2897,
    },
    {
      housing: 'Draußen',
      amount: 841,
    },
  ];

  const tabs: TabConfig[] = [
    {
      key: 'age',
      title: 'Alter',
    },
    {
      key: 'weight',
      title: 'Gewicht',
    },
    {
      key: 'sex',
      title: 'Geschlecht',
    },
    {
      key: 'activity',
      title: 'Aktivität',
    },
    {
      key: 'housing',
      title: 'Haltung',
    },
  ];

  return (
    <div className={'petProfiles'}>
      <div className={'petProfiles-filters'}>
        <div className={'petProfiles-filter'}>
          <IconFilter
            className={'petProfiles-filter-icon'}
            fill={'var(--color-text_secondary)'}
          />
          Tierart: Hunde
          <IconClose
            className={'petProfiles-filter-icon-close'}
            fill={'var(--color-text_tertiary)'}
          />
        </div>
      </div>
      <div className={'petProfiles-tabs'}>
        <Tabs tabs={tabs} dontSetHash noMargin>
          <Tab>
            <div className={'petProfiles-barChart'}>
              <BarChart
                data={dataAvgAge}
                xAxisKey={'age'}
                yAxisKey={'amount'}
                height={200}
                yAxisName={'Anzahl an Tieren'}
                xAxisName={'Alter'}
              />
            </div>
          </Tab>
          <Tab>
            <div className={'petProfiles-barChart'}>
              <BarChart
                data={dataAvgWeight}
                xAxisKey={'weight'}
                yAxisKey={'amount'}
                height={200}
                yAxisName={'Anzahl an Tieren'}
                xAxisName={'Gewicht'}
              />
            </div>
          </Tab>
          <Tab>
            <div className={'petProfiles-barChart'}>
              <BarChart
                data={dataSex}
                xAxisKey={'sex'}
                yAxisKey={'amount'}
                height={200}
                yAxisName={'Anzahl an Tieren'}
                xAxisName={'Geschlecht'}
                barColors={['var(--color-blue)', 'var(--color-primary)']}
              />
            </div>
          </Tab>
          <Tab>
            <div className={'petProfiles-barChart'}>
              <BarChart
                data={dataActivity}
                xAxisKey={'activity'}
                yAxisKey={'amount'}
                height={200}
                yAxisName={'Anzahl an Tieren'}
                xAxisName={'Aktivitätslevel'}
              />
            </div>
          </Tab>
          <Tab>
            <div className={'petProfiles-barChart'}>
              <BarChart
                data={dataHousing}
                xAxisKey={'housing'}
                yAxisKey={'amount'}
                height={200}
                yAxisName={'Anzahl an Tieren'}
                xAxisName={'Vorwiegende Haltung'}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default PetProfiles;
